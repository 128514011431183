import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import Api from 'pxp-api/clients/strapi';

import { RenderComponents } from '@/components/render-components/render-components';
import { QueryParam } from '@/enums/route';
import {
  createPageTitle,
  getPageTitleComponentTranslationKey,
} from '@/lib/title';
import { withServerSideSSUProps } from '@/lib/with-ssu';
import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import type { AppPageProps, NextPageWithLayout } from '@/types/app';

const SSUPage: NextPageWithLayout<AppPageProps> = ({
  ssu,
  page,
}: AppPageProps) => {
  const dataLayer = useDataLayer();
  const { t } = useTranslation();

  useEffect(() => {
    dataLayer.update({ page });
  }, [dataLayer, page]);

  if (!page?.components?.length) {
    return null;
  }

  const pageTitleTranslationKey = getPageTitleComponentTranslationKey({ page });
  const pageTitle: string | undefined = pageTitleTranslationKey
    ? // @ts-expect-error: translationKey is dynamic
      t(pageTitleTranslationKey)
    : undefined;

  return (
    <>
      {ssu && (
        <Head>
          <title>{createPageTitle(ssu, pageTitle)}</title>
        </Head>
      )}
      <RenderComponents components={page.components} />
    </>
  );
};

export const getServerSideProps = withServerSideSSUProps(
  async (context, ssu) => {
    const { params } = context;
    const slug = params?.[QueryParam.SSU_PATH]?.[0];
    const options = { preview: context.preview };

    const page = await Api.getPage(ssu.id, slug, options);

    if (!page) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        page,
      },
    };
  },
);

export default SSUPage;
